import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

import { onMounted, onUnmounted } from "vue";

// Define the prop

export default /*@__PURE__*/_defineComponent({
  __name: 'PokemonModal',
  props: {
    isVisible: { type: Boolean }
  },
  emits: ["close", "navigate"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const closeModal = () => {
  emit("close");
};

const prevPokemon = () => {
  emit("navigate", -1);
};

const nextPokemon = () => {
  emit("navigate", 1);
};

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "ArrowLeft") {
    prevPokemon();
  } else if (event.key === "ArrowRight") {
    nextPokemon();
  } else if (event.key === "Escape") {
    closeModal();
  }
};

onMounted(() => {
  window.addEventListener("keydown", handleKeydown);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-fade" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "modal-backdrop",
            onClick: closeModal
          }, [
            _createElementVNode("div", {
              class: "modal-content",
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createElementVNode("button", {
                onClick: prevPokemon,
                class: "nav-button left"
              }, "←"),
              _renderSlot(_ctx.$slots, "default"),
              _createElementVNode("button", {
                onClick: nextPokemon,
                class: "nav-button right"
              }, "→")
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})