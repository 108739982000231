import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_3 = {
  key: 1,
  class: "table table-hover table-striped table-bordered"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginationBar = _resolveComponent("PaginationBar")!
  const _component_PokemonPage = _resolveComponent("PokemonPage")!
  const _component_PokemonModal = _resolveComponent("PokemonModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("h1", null, "Welcome to My Pokédex", -1)),
    (_ctx.errorMessage)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMessage), 1))
      : (_openBlock(), _createElementBlock("table", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", { scope: "col" }, "Name")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paginatedPokemon, (pokemon) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: pokemon.name
              }, [
                _createElementVNode("td", null, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers(($event: any) => (_ctx.openPokemonModal(pokemon.name)), ["prevent"])
                  }, _toDisplayString(_ctx.capitalize(pokemon.name)), 9, _hoisted_4)
                ])
              ]))
            }), 128))
          ]),
          _createElementVNode("tfoot", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", null, [
                _createVNode(_component_PaginationBar, {
                  currentPage: _ctx.currentPage,
                  totalPages: _ctx.totalPages,
                  onNavigatePage: _ctx.goToPage,
                  canNavigatePrevious: _ctx.currentPage > 1,
                  canNavigateNext: _ctx.currentPage < _ctx.totalPages
                }, null, 8, ["currentPage", "totalPages", "onNavigatePage", "canNavigatePrevious", "canNavigateNext"])
              ])
            ])
          ])
        ])),
    _createVNode(_component_PokemonModal, {
      isVisible: _ctx.showModal,
      onClose: _ctx.closePokemonModal,
      onNavigate: _ctx.navigatePokemon
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PokemonPage, { name: _ctx.selectedPokemon }, null, 8, ["name"])
      ]),
      _: 1
    }, 8, ["isVisible", "onClose", "onNavigate"])
  ]))
}