import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  class: "btn-toolbar align-items-center justify-content-center",
  role: "toolbar",
  "aria-label": "Toolbar with button groups"
}
const _hoisted_2 = {
  class: "btn-group btn-group-sm me-2",
  role: "group",
  "aria-label": "Previous Group"
}
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  class: "btn-group btn-group-sm me-2",
  role: "group",
  "aria-label": "Pages Group"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = {
  class: "btn-group btn-group-sm me-2",
  role: "group",
  "aria-label": "Next Group"
}
const _hoisted_9 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        disabled: !_ctx.canNavigatePrevious,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('navigatePage', _ctx.currentPage - 1)))
      }, " Previous ", 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("button", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('navigatePage', 1))),
        class: _normalizeClass(['btn', 'btn-primary', { active: _ctx.currentPage === 1 }])
      }, " 1 ", 2),
      (_ctx.currentPage > 3)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, "..."))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedPages, (page) => {
        return (_openBlock(), _createElementBlock("button", {
          key: page,
          class: _normalizeClass(['btn', 'btn-primary', { active: page === _ctx.currentPage }]),
          onClick: ($event: any) => (_ctx.$emit('navigatePage', page))
        }, _toDisplayString(page), 11, _hoisted_6))
      }), 128)),
      (_ctx.currentPage < _ctx.totalPages - 2)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "..."))
        : _createCommentVNode("", true),
      (_ctx.totalPages > 1)
        ? (_openBlock(), _createElementBlock("button", {
            key: 2,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('navigatePage', _ctx.totalPages))),
            class: "test btn btn-primary btn-sm"
          }, _toDisplayString(_ctx.totalPages), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("button", {
        class: "btn btn-primary",
        disabled: !_ctx.canNavigateNext,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('navigatePage', _ctx.currentPage + 1)))
      }, " Next ", 8, _hoisted_9)
    ])
  ]))
}