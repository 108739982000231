import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "pokemon-card-header" }
const _hoisted_4 = { class: "pokemon-card-image" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "pokemon-card-details" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { class: "alert alert-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("p", null, "Loading Pokémon details...", -1)
        ])))
      : (_ctx.pokemonDetails)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.capitalize(_ctx.pokemonDetails.name)), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              (_ctx.pokemonDetails.sprites && _ctx.pokemonDetails.sprites.front_default)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: _ctx.pokemonDetails.sprites.front_default,
                    alt: "Pokemon image"
                  }, null, 8, _hoisted_5))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.pokemonDetails.types && _ctx.pokemonDetails.types.length > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, [
                    _cache[1] || (_cache[1] = _createElementVNode("b", null, "Type: ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pokemonDetails.types, (typeObj, index) => {
                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                        _createTextVNode(_toDisplayString(_ctx.capitalize(typeObj.type.name)), 1),
                        (index < _ctx.pokemonDetails.types.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, ", "))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (_ctx.pokemonDetails.stats && _ctx.pokemonDetails.stats.length > 0)
                ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                    _cache[2] || (_cache[2] = _createElementVNode("b", null, "Stats: ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pokemonDetails.stats, (statObj, index) => {
                      return (_openBlock(), _createElementBlock("span", { key: index }, [
                        _createTextVNode(_toDisplayString(_ctx.capitalize(statObj.stat.name)) + " (Base: " + _toDisplayString(statObj.base_stat) + ", Effort: " + _toDisplayString(statObj.effort) + ")", 1),
                        (index < _ctx.pokemonDetails.stats.length - 1)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_10, ", "))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ]))
        : (_ctx.errorMessage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.errorMessage), 1)
            ]))
          : _createCommentVNode("", true)
  ]))
}