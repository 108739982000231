import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "py-3 mb-4 border-bottom" }
const _hoisted_2 = { class: "container d-flex flex-wrap justify-content-center" }
const _hoisted_3 = { class: "fs-4" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "py-3" }
const _hoisted_6 = { class: "container" }
const _hoisted_7 = { class: "text-center text-body-secondary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("main", null, [
    _createElementVNode("header", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none"
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_3, [
              _createElementVNode("img", {
                src: _ctx.pokemonLogo,
                alt: "Pokemon Logo",
                class: "d-inline-block align-top",
                width: "120",
                height: "67"
              }, null, 8, _hoisted_4)
            ])
          ]),
          _: 1
        }),
        _createElementVNode("form", {
          class: "col-12 col-lg-auto mb-3 mb-lg-0",
          role: "search",
          onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.updateSearch && _ctx.updateSearch(...args)), ["prevent"]))
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "search",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event)),
            class: "form-control",
            placeholder: "Search...",
            "aria-label": "Search"
          }, null, 512), [
            [_vModelText, _ctx.searchQuery]
          ])
        ], 32)
      ])
    ]),
    (_openBlock(), _createBlock(_component_router_view, {
      class: "container",
      key: _ctx.$route.fullPath
    })),
    _createElementVNode("footer", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("p", _hoisted_7, [
          _createTextVNode(" © " + _toDisplayString(new Date().getFullYear()) + " ", 1),
          _cache[2] || (_cache[2] = _createElementVNode("a", {
            target: "_blank",
            title: "Tom Metcalfe Github",
            href: "https://github.com/iamtommetcalfe"
          }, "Tom Metcalfe", -1))
        ])
      ])
    ])
  ]))
}